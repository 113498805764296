@import '../variables';
@import '~bootstrap/scss/bootstrap';
@import '../header/header';
@import '../../../App/css/app';


@media (min-width: $widthWide){
	@import "./appWide";
}
@media (min-width: $widthNarrow + 1) and (max-width: $widthWide - 1){
	@import "./appMid";
}
@media (max-width:$widthNarrow){
	@import "./appNarrow";
}


html,body{
	font-family: Cambay, sans-serif;
	line-height: 1.5;
}

html.light, body.light{
	background-color:$Color4Light;
}
body{
	background-color:$Color4;
	position: relative;
	color:$Color5;
	width:100%;
}

a, a:visited,a:hover,a:active{
	color:$Color5;
	text-decoration: none;
}

a, div{
	.text-centered{
	text-align: center;
	position: relative;
	display: block;
	}
}
 
img{
	vertical-align: top;
}

.hidden{
	width: 0px;
    height: 0px;
    border: none;
    padding: 0px;
}

#Innerbody{
	max-width: $maxAppWidth;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
}

#Footer{
	height: 125px;
}

button{
	background-color: transparent;
	color:white;
	border: none;
}


//Popups--------------------------------------------------------------------------------------------
#Popup{
	background-color: $Color3;
	z-index:6;
	display: none;
	position: fixed;
	text-align: center;
	padding: 6px;
	left:50%;
	border:solid $Color1 2px;
	border-radius: $borderRadius;
	div{
		z-index: inherit;
	}
	textarea{
		flex:2;
	}
}

#PopupBG{
	display: none;
	z-index: 4;
	background-color: rgba(0,0,0,.75);
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
}

#popupContainer{
	//border:solid green 2px;
	position: relative;
	width:100%;
	height:100%;
}
#popupContainer.popupOptions{
	display: flex;
	flex-direction: column;
}
.popupOption{
	display: block;
	flex:1;
	margin:3px 0px;
	width:100%;
	span,div{
		flex:1;
	}
}

.popupButton{
	display: block;
	cursor: pointer;
	margin-left:auto;
	margin-right:auto;
	background-color: $Color1;
	padding: 5px 3px;
	border: none;
	border-radius: $borderRadius;
}

.popupButton:hover{
	background-color: $Color2;
}

.popupOption.button{
	display: flex;
	cursor: pointer;
	border:solid $Color4 1px;
	background-color: transparent;
	color:white;
	line-height: 2;
	i{
		flex:1;
	}
	a,input,span{
		flex:4;
		border: none;
		background-color: transparent;
		color:$Color5;
	}
}


.popupOption.button:hover{
	background-color: rgba(0,0,0,.2);
}

textarea.popupOption{
	cursor:text;
}
.popupOption.Row{
	display: flex;
	flex-direction: row;
	div{
		flex:1;
	}
}

#Popup.popupAccountMenu{
	border-radius: 0px 0px 0px $borderRadius;
	border:solid $Color1 2px;
	left:unset;
}

//report textarea
#ReportText{
	flex:3;
}

