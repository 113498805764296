
@import "./header_vars";

body{
	top:var(--bannerHeight);
}

#BannerContainer{
	width: 100%;
	position: fixed;
	top:0;
	background-color: $Color1;
	z-index: 5;
}

#BannerContainer{
	box-shadow:0px 3px 10px 5px rgba(40,40,40,.5);
}

#Banner{
	height:var(--bannerHeight);
	display:flex;
	position:relative;
	top:0;
	width:100%;
	padding:var(--bannerPadding);
	margin-left:auto;
	margin-right: auto;
	z-index: 5;
	background-color: $Color1;
	.flexFiller{
		flex:var(--flexFillerFlex);
	}
}
.bannerItemContainer{
	height:100%;
	padding:var(--itemContainerPadding);
}
.bannerButton{
	display:block;
	height:100%;
	width:100%;
	overflow: hidden;
	cursor: pointer;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	padding:var(--bannerButtonPadding);
	border-radius: $borderRadius;
	font-size: var(--bannerButtonFontSize);
	.text-centered{
		height:100%;
		text-transform: uppercase;
		font-weight: bold;
		line-height: var(--textLineHeight);
	}
}
.bannerButton:hover{
	background-color:$Color2;
}

.bannerButtonImage{
	height: 100%;
	width:auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

#BannerButtonHome{
	background-image: url('../../../images/TheDotDogLogo.svg');
}
#BannerButtonHome:hover{
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}
#BannerButtonUpload{
	background-image: url('../../../images/TheDotDogLogo.svg');
}
#BannerButtonUpload:hover{
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}
#BannerButtonAbout{
	background-image: url('../../../images/TheDotDogLogo.svg');
}
#BannerButtonAbout:hover{
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}
.loadFilledLogo{
	height: 0px;
	width: 0px;
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}

#BannerHomeContainer,#BannerUploadContainer,#BannerAboutContainer{
	flex:var(--bannerIconFlex);
	flex-basis: var(--bannerIconFlexBasis);
	min-width: var(--bannerIconFlexBasis);
}
#BannerSignupContainer,#BannerLoginContainer,#BannerAccountContainer{
	.bannerButton{
		border: solid white 2px;
		border-radius: $borderRadius;
	}
	.bannerButton:hover{
		color:$Color2;
		background-color:white;
	}
}
#BannerSignupContainer,#BannerLoginContainer{
	flex:var(--loginSignupFlex);
}
#BannerAccountContainer{
	flex:var(--accountFlex);
	margin:0px 5px
}

